@import '../../style/abstracts/_abstracts-dir';

.profile-container{
    padding-top: 10px;
    .profile-heading-text{
      font-size: 26px;
      line-height: 32px;
      margin-left: 15px;
      margin-bottom: 15px;
      color: $cardHeadingTextColor;
      font-weight: bold;

      @media only screen and (max-width: 768px) {
        margin-bottom: 5px;
        margin-left: 0px;
      }
    }

    .makeStyles-grid-14{
      margin-left: 0px ;
      margin-right: 0px ;
    }

    .profile-container-left{
      margin-top: 50px;
    }

    .profile-container-right{
      padding: 10px;
      .profile-container-right-image{
        height: 100px;
        width: 100px;
        border-radius: 50%;
      }
    }

    .profile-sub-container{
      cursor: pointer;
      background-color: white;
      padding: 20px;
      margin-top: 10px;
      border-radius: 7px;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
     
      .profile-sub-container-header{
        display: flex;
        align-items: center;
        justify-content: space-between;


        @media only screen and (max-width: 768px) {
          flex-direction: column;
          align-items: flex-start;
        }
  
        .edit-profile-btn-container{
          display: none;

          @media only screen and (max-width: 768px) {
            display: block;
          }
        }
      }

      &:hover{
        .edit-profile-btn-container{
          display: block;
          transition: all .4s;
        }
      }
    }

    .profile-container-heading{
      color: gray;
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 20px;

      @media only screen and (max-width: 768px) {
        margin-bottom: 0px;
      }
    }

    .profile-container-sub-heading{
      color: black;
      font-size: 16px;
      font-weight: 700;

      @media only screen and (max-width: 768px) {
        margin-bottom: 10px;
      }
    }
}

.profile-main-container{
  background: $linkWaterColor;
}
