@import '../../style/abstracts/_abstracts-dir';

.cgs-video-form {
  >div:not(:last-child) {
    padding-bottom: 16px;
  }

  .cgs-video-form--btn {
    .cgs-video-form--btn-label {
      @include setFont($font-size--md, $font-weight--regular);
      text-transform: none;
    }
  }
}

.cgs--home {
  > div:not(:last-child) {
    margin-bottom: 32px;
  }
  .cgs--home-header {
    color: $headerWhiteColor;
    @include setFont($font-size--lg, $font-weight--bold);
    border-bottom: $headerWhiteColor 0 solid;
    padding-bottom: 8px;
    margin-left: 15px;
    padding-top: 20px;
    margin-bottom: 10px;

    .cgs--home-header-bread-crum {
      font-size: 14px;
      color: black;
    }
  }
}

.cgs--home-header-heading {
  text-align: center;
  padding: 5px 10px 10px;
}

.home-form-container{
  background-image: url('../../assets/img/buyhome.webp');
  background-repeat: no-repeat;
  height: 100%;

  // @media only screen and (max-width: 900px) {
  //   background-size: cover;
  // }

  .home-header-container {
    padding-top: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-page-form-heading {
    font-size: 42px;
    color: #fff;
    letter-spacing: 0.8px;
    text-shadow: 0 0 10px rgb(0 0 0 / 30%);
    @media only screen and (max-width: 700px) {
      font-size: 34px;
      margin-top: 20px;
    }

    @media only screen and (max-width: 320px) {
      font-size: 30px;
      margin-top: 20px;
    }
  }

  .home-form-container-text-data{
    margin: auto;
    width: 70%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .home-fee-container-data{
    font-size: 28px;
    @media only screen and (max-width: 700px) {
      font-size: 22px;
    }

    @media only screen and (max-width: 320px) {
      font-size: 18px;
      margin-top: 20px;
    }
  }

  .home-fee-container-data span em span {
    margin-left: 10px;
    margin-right: 10px;
  }

  .home-pay-heading-container{
    color: #FFFFFF;
    font-size: 29px;
    line-height: 1.1em;
    letter-spacing: 0.9px;
    text-shadow: 0 0 10px rgb(0 0 0 / 30%);
    @media only screen and (max-width: 700px) {
      font-size: 23px;
    }
  }

  .short-form-heading{
    color: #FFFFFF;
    font-size: 20px;
    box-sizing: border-box;
  }

  .can-call-us-text{
    h4{
      color: black;
      text-align: center;
      font-weight: 400;
      font-size: 21px;
    }
  }
}

.how-we-help-form-container{
  .address-form-input{
    width: 100%;
  }
  .sign-in-container{
    width: 65%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 1rem;
    margin: 20px auto;
    padding: 20px 15px;
    background-color: white;

    @media only screen and (max-width: 475px) {
      width: 80%;
    }
  }
}

.sign-in-container-social {
  padding-top: 10px;
  margin-bottom: 30px;
}

.address-form-cash-btn-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;

  .how-we-help-get-your-best-btn{
    background: #151E30;
    outline: none;
    border: none;
    width: 100%;
    margin-top: -15px;
    border-radius: 4px;
    padding: 10px;
    color: white;
    font-size: 1.25rem;
    font-family: inherit;

    @media only screen and (max-width: 600px) {
      font-size: 0.95rem;
    }

    &:hover{
      background-color: #243454;
    }

  }
}

.home-login-back-image{
  height: 95%;
  width: 100%;
  display: flex;
  align-self: flex-end;
  margin-left: 10px;
  background-size: 100%;
  border-radius: 8px;
}
