@import '../../style/abstracts/_abstracts-dir';

.list-fraction {
  float: right;
}


.float-right {
  float: right;
}


