@import '../../style/abstracts/_abstracts-dir';

.cgs {
  background-color: setSlateGrayOpacity(0.3);
  height: 100%;
}

.MuiGridList-root.cgs--grid{
  margin: 0px;
}
