.slider-demo .p-slider-horizontal, .slider-demo .p-inputtext {
  width: 14rem;
}

.slider-demo .p-slider-vertical {
  height: 14rem;
}

.virtualscroller-demo .scroll-item {
  display: flex;
  align-items: center;
}

.virtualscroller-demo .custom-scroll-item {
  flex-direction: column;
  align-items: stretch;
}

.virtualscroller-demo .odd {
  background-color: var(--surface-b);
}

.virtualscroller-demo .p-virtualscroller {
  height: 200px;
  width: 385px;
  border: 1px solid var(--surface-d);
}

.virtualscroller-demo .p-horizontal-scroll .p-virtualscroller-content {
  display: flex;
  flex-direction: row;
}

.virtualscroller-demo .p-horizontal-scroll .scroll-item {
  writing-mode: vertical-lr;
}