@mixin respond-to($breakpoint) {
  @if $breakpoint == "xs" {
    @media (max-width: 576px) {
      @content;
    }
  }

  @else if $breakpoint == "sm" {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $breakpoint == "md" {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $breakpoint == "lg" {
    @media (min-width: 1200px) {
      @content;
    }
  }

  @else if $breakpoint == "xl" {
    @media (min-width: 1400px) {
      @content;
    }
  }
}

@mixin setFont($size, $weight) {
  font-family: 'Quicksand', sans-serif;
  font-size: $size;
  font-weight: $weight;
}
