@import '../../style/abstracts/_abstracts-dir';

.need-to-sell-container{
  background-color: #fff;
  margin: 0 15px;
  padding: 0px 20px;

  @media only screen and (max-width: 900px) {
    margin-right: 30px;
  }

  &-heading{
    font-size: 40px;
    color: rgba(0,0,0,0.85);
    line-height: 1.2em;
    text-align: center;

    @media only screen and (max-width: 900px) {
      font-size: 30px;
    }

    @media only screen and (max-width: 600px) {
      font-size: 24px;
    }
  }

  &-description{
    font-size: 1.6rem;
    text-align: center;
    font-weight: 400;

    @media only screen and (max-width: 900px) {
      font-size: 1.3rem;
    }

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
    }
  }

  &-paragraph{
    text-align: center;
  }
}
