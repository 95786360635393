@import '../../style/abstracts/_abstracts-dir';

.dialog-box {

  .dialog-box--title {
    @include setFont($font-size--md, $font-weight--regular);
    border-bottom: 1px $xiketic solid;
  }

  .dialog-box--content {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    margin-bottom: 24px;

    >div:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.dialog-box--btn {
  .dialog-box--btn-label {
    text-transform: none;
    @include setFont($font-size--md, $font-weight--regular);
  }
}
