@import '../../style//abstracts/_abstracts-dir';

.footer-item-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  margin: auto !important;

  @media only screen and (max-width: 768px) {
    flex-wrap: wrap;
  }
}

.footer-list-item-contaienr.MuiListItem-gutters {
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}
.footer-list-item-contaienr a {
  transition: all 0.4s;
  transition-duration: 1;
}

.footer-list-item-contaienr a:hover {
  background: $pewterBlue;
  transform: translateY(-5px);
}

.footer-creative-team {
  color: $cardHeadingTextColor;
}

.footer-list-item-contaienr .makeStyles-block-52 {
  @media only screen and (max-width: 768px) {
    padding: 1px;
  }
}

.footer-container {
  padding-top: 40px;
  background-color: #d3d8dc;
  &__image {
    height: 95px;
    width: 137px;
    margin-left: -20px;

    @media only screen and (max-width: 768px) {
      margin-left: 0;
    }
  }

  &__desc {
    color: black;
    width: 280px;
    font-family: inherit;
    margin-right: -100;
    margin-top: -25px;
    text-align: left;
    font-size: 15px;
    line-height: 34px;
    padding-bottom: 10px;
  }

  &__list-title {
    font-weight: 700;
    color: #4797bb;
    margin-left: 20px;
  }

  &__list-items {
    margin-top: 10px;
    margin-left: 20px;
    &__title {
      font-family: inherit;
      font-size: 18px;
      color: black;
      text-decoration: none;
      a {
        font-family: inherit;
        font-size: 18px;
        color: black;
        text-decoration: none;
      }
    }

    &__number {
      color: black;
      font-size: 26px;
      font-family: Roboto;
    }

    &__email {
      font-size: 16px;
      font-family: Roboto;
      white-space: pre-wrap;
      width: 250px;
      color: black;
      word-break: break-all;
    }

    &__social-container {
      display: flex;
      align-items: center;
      margin-top: 30px;

      &-icon {
        border: 1px solid #4797bb;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        padding: 10px;
        cursor: pointer;
        transition: all 0.4s;
        &:hover {
          background: #4797bb;
          color: white;
        }
      }
    }
  }

  &__border {
    width: 100%;
    border: 1px solid #4797bb;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  &__copy-right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #4797bb;
    margin-bottom: 30px;
  }

  &__consulting-text {
    text-decoration: none;
    color: #4797bf;
    font-weight: 700;
  }
}

.social-icon-right {
  margin-right: 15px;
}
