@import '../../style/abstracts/_abstracts-dir';


.way-card-container-inside{
    transition: all .2s;
    &:hover{
        transform: translateY(5%);
        
    }
}

.way-card-text-heading{
    text-align: center;
    color: $cardHeadingTextColor;
    font-size: 22px;
    width: 95%;

    @media only screen and (max-width: 900px) {
        font-size: 20px;
    }
}
.ways-card-text-desc{
    text-align: center;
    font-size: 18px;
    width: 95%;
    line-height: 22px;
    margin-bottom: 20px;

    @media screen {
        
    }
}

.way-card-container-image{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    &-img{
        height: 80px;
        width: 100px;
    }
}

