@import '../../style/abstracts/_abstracts-dir';


.section-tab-header{
    padding: 10px 0px !important;
    margin: 0px 0px !important;
}
 
.section-tab-header .makeStyles-container-35{
    box-shadow: rgba(30 ,166 ,224 , .1) 0px 5px 20px;
    padding: 20px 0px;
    @media only screen and (max-width: 768px) {
        padding: 10px 0px;
        width: 91%;
    }
}


.makeStyles-section-142.section-tab-header{
    box-shadow: rgba(30, 166, 224, .1) 0px 5px 20px;
    border-radius: 7px;
}

.makeStyles-section-34.section-tab-header{
    border-radius: 10px;
    background-color: white;
}


.navigation-pills-container{
  text-align: center;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 25px;

  .navigation-pills-heading{
    color: $cardHeadingTextColor;
    text-align: center;
    font-family: inherit;
    position: relative;
    z-index: 10;
  }
}

.nav-tabs-desc{
  font-size: 18px;
  margin: 10px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: inherit;
  margin-bottom: 20px;
  &__text{
    display: inline-block;
    // margin-bottom: 10px;

  }
}

.home-view-about-us-desc{
  font-weight: 600;
  font-family: inherit;
  transition: all .4s;
  transform-origin: top;
  margin-bottom: 10px;
  margin-top: 10px;
  text-align: center;
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
}

.home-view-heading-top{
  margin-top: 20px;
  margin-bottom: 30px;
}

.home-view-heading{
  font-weight: 700;
  font-size: 40px;
  text-align: center;
  color: #4797b9;

  @media only screen and (max-width: 768px) {
    font-size: 30px;
  }

}

.nav-tabs-long-desc{
  font-size: 14px;
  margin: 10px;
  margin-left: 30px;
  margin-right: 30px;
  font-family: inherit;
  margin-bottom: 40px;
  font-weight: 400;
}

.can-we-help-bottom-desc{
  margin-top: 20px;
  font-size: 14px;
  font-family: inherit;
  line-height: 18px;
}

.home-view-heading-container{
  margin: 50px 0;
}

.home-view-heading-blue{
  font-weight: 700 !important;
  font-size: 40px !important;
  text-align: center;
  font-family: Roboto;
  color: #4797b9;
}

.section-card-grid-container{
  @media only screen and (max-width: 900px) {
    margin-bottom: 20px !important;
  }
}
