.how-we-help-form-container .MuiFormControl-root.MuiTextField-root{
  width: 100%;
  margin-bottom: 15px;
  color: white !important;
}

.address-form-get-best-offer{
  background-color: #151E30;
  width: 40%;
  margin: auto;
  color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transform-origin: top;
  transition: transform .5s;
  margin-bottom: 20px;
  margin-top: 20px;

  &:hover{
    transform: translateY(10px);
  }

  @media only screen and (max-width: 425px) {
    width: 65%;
  }

  @media only screen and (max-width: 375px) {
    width: 75%;
  }
}