@import '../../style/abstracts/_abstracts-dir';

.cgs {
  // background-color: setSlateGrayOpacity(0.3);
  height: 100%;
}

.layout-main-container{
  background: $linkWaterColor;
}

.layout-header-container {
  padding-top: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}