// gray
$slateGray: rgb(111, 125, 140);

// black
$xiketic: rgb(11, 0, 20);

// blue
$pewterBlue: rgb(119, 160, 169);

$headerBlueColor: white;


$headerWhiteColor: #fff;

$cardHeadingTextColor: #4797b9;

$headingTextColor: #4f9bbb;

$linkWaterColor : #D3D8DC;

$homeFooterBack: #031134