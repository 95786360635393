@import "../../style/abstracts/_abstracts-dir";

.whyhelp-main-container {
  background-color: $linkWaterColor;
  .whywe-help-heading {
    font-size: 22px;
    font-weight: 500;
    font-family: inherit;
    text-align: center;
    margin-top: 20px;
    color: #fff;

    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .whywe-help-heading-sub {
    font-size: 22px;
    font-weight: 500;
    font-family: inherit;
    text-align: center;
    color: #fff;

    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .whywe-help-desc {
    font-size: 16px;
    font-weight: normal;
    font-family: inherit;
    color: #fff;
    font-weight: 600;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }

  .section-pills-upper-view {
    margin-top: 110px;

    @media only screen and (max-width: 1024px) {
      margin-top: 60px;
    }

    @media only screen and (max-width: 500px) {
      margin-top: 120px;
    }

    @media only screen and (max-width: 425px) {
      margin-top: 30px;
    }
  }

  .centered-list-item {
    width: 100%;
    margin-top: 40px;
    padding-bottom: 30px;
    text-align: center;
    align-self: center;
    color: #00b8d9;
    font-size: 48px;
    font-weight: 900;
    font-family: inherit;

    &:hover {
      transform: translateY(5px);
      color: #00b8d9;
    }
  }

  .list-container {
    @media only screen and (min-width: 600px) {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .list-items-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    @media only screen and (min-width: 768px) {
      margin-top: 60px;
      flex-direction: row;
    }

    @media only screen and (min-width: 600px) {
      margin-top: 60px;
    }

    @media only screen and (max-width: 425px) {
      display: flex;
      flex-direction: column;
      margin-top: -20px;
      margin-left: -20px;
    }
  }

  .why-we-help-list-mobile-view {
    flex: 0.5;
    display: flex;
    margin-top: -50px;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 500px) {
      margin-top: 40px;
    }
    @media only screen and (min-width: 426px) {
      display: none;
    }
  }

  .why-we-help-list-desktop-view {
    flex: 0.5;
    display: flex;
    margin-top: -50px;
    align-items: center;
    flex-direction: column;

    @media only screen and (max-width: 500px) {
      margin-top: 40px;
    }

    @media only screen and (max-width: 425px) {
      display: none;
    }
  }

  .why-we-help-list-item {
    width: 50%;
    font-size: 16px;
    list-style: none;
    margin-bottom: 4px;
    transition: all 0.4s;
    transform-origin: left;
    cursor: default;
    font-size: 1.10286em;
    font-weight: 600;
    font-family: inherit;

    @media only screen and (max-width: 1024px) {
      width: 100%;
      font-size: 16px;
    }

    @media only screen and (max-width: 1024px) {
      width: 100%;
      font-size: 16px;
    }

    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }

    &:hover {
      transform: translateX(10px);
      color: #00b8d9;
    }
  }
  .why-we-help-yourself {
    font-size: 16px;
    font-weight: 500;
    font-family: inherit;
    margin-top: 20px;
    font-weight: 600;
  }

  .why-we-help-list-item::before {
    content: "\00BB";
    margin-left: -20px;
    margin-right: 10px;
    font-size: 16px;
  }

  .why-we-help-desc {
    font-size: 16px;
    font-weight: normal;
    font-family: inherit;
    @media only screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  .why-we-help-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.why-we-help-container {
  background-image: url("../../assets/img/whywehelp.jpg");
  position: absolute;
  height: 260px;
  width: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  background-size: cover;
  background-repeat: no-repeat;

  @media only screen and (max-width: 1280px) {
    height: 320px;
  }

  @media only screen and (max-width: 1200px) {
    height: 350px;
  }

  @media only screen and (max-width: 900px) {
    height: 300px;
  }

  @media only screen and (max-width: 500px) {
    height: 350px;
  }

  @media only screen and (max-width: 475px) {
    height: 330px;
  }

  @media only screen and (max-width: 320px) {
    height: 400px;
  }
}

.why-we-help-main-container {
  position: relative;
}
