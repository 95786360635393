@import '../../style/abstracts/_abstracts-dir';

.home-header-flow-item{
    display: flex;
    width: 70%;
    margin-top: 70px;
    align-self: center;
    justify-content: space-around;
    height: 47px;
    align-items: center;

    @media only screen and (max-width: 768px) {
        display: none;
    }

    .home-header-flow-item-list{
       cursor: pointer;
        height: 100%;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        color: white;
        font-size: 16px;
        font-weight: 500;
        transition: all .3s;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-left: 10px;
        text-decoration: none;
        
        transition-timing-function: cubic-bezier(.17,.67,.83,.67);

        @media only screen and (max-width: 1024px) {
            font-size: 14px;
        }

        a{
            text-decoration: none;
            color: white;
        }
        a:hover{
            // background: $cardHeadingTextColor;
            transform-origin: top bottom;
            color: white;
           
            
        }

        &:hover{
            background: #4797b9;
            transform-origin: top bottom;
            color: white;
            border-radius: 5px;
            transform: scale(1.1);
        }
    }

    .link-focused{
        cursor: pointer;
        height: 100%;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        transition: all .3s;
        display: flex;
        border-radius: 5px;
        justify-content: flex-end;
        align-items: center;
        margin-left: 10px;
        text-decoration: none;
        background: #4797b9;
    }
}
