@import '../../style/abstracts/_abstracts-dir';

.cgs-message {
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 10px;
  .cgs-message--text {
    @include setFont($font-size--md, $font-weight--regular);
  }
}
